import service from "../service.js";

export const serchDeviceAssess = params => {
    return service({
        method: 'POST',
        url: '/deviceAssess/serchDeviceAssess',
        params
    })
}

export const addDeviceAssess = (data) => {
    return service({
        method: 'POST',
        url: '/deviceAssess/addDeviceAssess',
        data
    })
}

export const updateDeviceAssess = (data) => {
    return service({
        method: 'POST',
        url: '/deviceAssess/updateDeviceAssess',
        data
    })
}


export const getDeviceAssess = (id) => {
    return service({
        method: 'GET',
        url: '/deviceAssess/getDeviceAssess',
        params: {
            id
        }
    })
}

export const getMonthUseNum = () => {
    return service({
        method: 'GET',
        url: '/deviceAssess/getMonthUseNum',
        
    })
}

export const assessList = [{ code: '00', name: 'I级' }, { code: '01', name: 'II级' }, { code: '02', name: 'III级' }, { code: '03', name: 'Ⅳ级' }, { code: '04', name: 'V级' }]

export const useYearList = [{ code: '00', name: '0-3年' }, { code: '01', name: '3-6 年' }, { code: '02', name: '6-9 年' }, { code: '03', name: '9-12 年' }, { code: '04', name: '12 年以上' }]

